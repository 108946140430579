import React from 'react'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import Cards from '../cards/Cards/Cards'
import IndividualApproachText from '../IndividualApproachText/IndividualApproachText'
import PlansComparison from '../plansComparison/PlansComparison/PlansComparison'
import { useFillStore } from '../zustand/fn/fillStore'
import CurrentPlanPanel from '../currentPlanPanel/CurrentPlanPanel/CurrentPlanPanel'
import TariffsHeader from '../TariffsHeader/TariffsHeader'

// Статья с карточками подписки
function MySubscriptionArticle() {
	useFillStore()

	return (
		<ArticleContentWrapper innerWrapperWidth="middle">
			<CurrentPlanPanel />
			<TariffsHeader />
			<Cards />
			<IndividualApproachText />
			<PlansComparison />
		</ArticleContentWrapper>
	)
}

export default MySubscriptionArticle
