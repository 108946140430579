import cn from 'classnames'
import React, { ReactNode } from 'react'
import { CrownOutlined, FlagOutlined, StarOutlined } from '@ant-design/icons'
import s from './TariffSign.module.scss'

type TariffSignProps = {
	// Тип значка
	type: 'flag' | 'star' | 'crown'
	// Текущий ли тариф? Значок будет иметь голубой фон.
	current?: boolean
	extraClass?: string
}

// Значок тарифа
function TariffSign(props: TariffSignProps) {
	const { type, current, extraClass = '' } = props

	const iconMapper: Record<typeof type, ReactNode> = {
		flag: <FlagOutlined className={s.icon} />,
		star: <StarOutlined className={s.icon} />,
		crown: <CrownOutlined className={s.icon} />,
	}

	return (
		<div
			className={cn(
				s.wrapper,
				current && s['wrapper--current'],
				extraClass
			)}
		>
			{iconMapper[type]}
		</div>
	)
}

export default TariffSign
