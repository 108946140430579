import React from 'react'
import s from './IndividualApproachText.module.scss'

function IndividualApproachText() {
	return (
		<div className={s.blockWrapper}>
			<p className={s.mainText}>
				Нужен индивидуальный подход? Напишите нам на{' '}
				<a href="mailto:sales@credu.ai">sales@credu.ai</a> и мы
				разработаем для вас индивидуальный план
			</p>
		</div>
	)
}

export default IndividualApproachText
