const leadsEn = {
	// ЭКРАН С СООБЩЕНИЕМ ОБ ОТСТУТСТВИИ ПОКУПОК
	// Сообщение про отсутствие покупок
	noLeadsScreenNoLeads: "There's not a single purchase",

	// СТАТИСТИКА ПО ПОКУПКАМ
	// Заголовок раздела общего количества покупок
	leadsStatsTotalPurchasesHeader: 'Purchases total',
	// Заголовок раздела количества покупок за сегодня
	leadsStatsTodayPurchasesHeader: "Today's purchases",

	// ТАБЛИЦА С ПОКУПКАМИ
	// Заголовок ряда «Дата»
	leadsTableHeaderDate: 'Date',
	// Заголовок ряда «Электронная почта»
	leadsTableHeaderEmail: 'E-mail',
	// Заголовок ряда «Телефон»
	leadsTableHeaderPhone: 'Phone nu,ber',
	// Заголовок ряда «Стоимость»
	leadsTableHeaderCost: 'Cost',
	// Заголовок ряда «Платежная система»
	leadsTableHeaderPaymentSystem: 'Payment system',
	// Заголовок ряда «Статус»
	leadsTableHeaderStatus: 'Status',
	// Статус платежа: Оплачен
	leadsTableStatusPaid: 'Paid',
	// Статус платежа: Не оплачен
	leadsTableStatusUnPaid: 'Not paid',
	leadsTablePaySystemHaveNotSelected: 'Not specified',
}

export default leadsEn
