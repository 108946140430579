import PaymentTypes from 'parts/types/PaymentTypes'

export function getIconType(
	thisPlan: PaymentTypes.TariffPlan,
	plans: PaymentTypes.TariffPlan[]
) {
	const plansScoreOrder = getPlansScoreOrder(plans)

	const thisPlanIdx = plansScoreOrder.findIndex(
		(plan) => plan.id === thisPlan.id
	)

	const iconMapper = ['flag', 'star', 'crown']

	return iconMapper[thisPlanIdx] ?? 'crown'
}

export function getPlansScoreOrder(plans: PaymentTypes.TariffPlan[]) {
	return plans.sort((aPlan, bPlan) => {
		// Если план бесплатный, то поставить в начало очереди
		if (!aPlan.isPaid || !bPlan.isPaid) {
			return 1
		}

		// Если в плане нет цен, то поставить в начало очереди
		if (!aPlan.prices.length || !bPlan.prices.length) {
			return 1
		}

		// Сортировать по возрастанию цены. Чем выше — тем лучше.
		return aPlan.prices[0].amount < bPlan.prices[0].amount ? -1 : 1
	})
}
