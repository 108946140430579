// НАЗВАНИЯ СТРАНИЦ. Используется в навигации и заголовках страниц.

const Auth = {
	Login: 'Entry',
	Register: 'Registration',
	Register_Owner_Start: 'Administrator registration',
	Register_Owner_School: 'Creation of a school',
	Register_Methodist: 'Registration of a methodologist',
	Register_ConfirmEmail: 'Entering a verification code',
	RecoveryStart: 'Password restore',
	RecoveryCheck: 'Entering a confirmation code',
	RecoverySet: 'Set a new password',
	RecoveryFinish: 'Password set',
	MainLanding: 'Landing',
	DeleteAccount: 'Delete account',
	DeleteAccountConfirm: 'Account deletion confirmation',
}

export const MainUrls = {
	Main: 'Main',
	SchoolMain: 'School',
	Success: 'Successful purchase',
	Fail: 'The purchase failed',
}

export const StudentUrls = {
	Students: 'Students',
	Student_Account: 'Profile',
	Student_Orders: 'Orders',
	Student_History: 'Action log',
	Student_Visits: 'Visits',
	Student_Visit: 'Course',
}

export const StaffUrls = {
	Staff: 'Employees',
	Employee: 'Employee profile',
	Employee_Works: 'Employee groups',
	Employee_Rights: 'User rights',
}

const Training = {
	Trainings: 'My courses',
	Training: 'Course',
	Training_Groups: 'Groups',
	Training_LandingConstructor: 'Website builder',
	Training_LandingConstructorPreview: 'Preview of one page promo site',
	Training_GroupConditions: 'Passing conditions',
	Training_GroupPayments: 'Tariff builder',
	Training_GroupCurators: 'Curators and managers',
	Training_GroupOpenLessons: 'Opened lessons',
	Lesson: 'Exercise constructor',
	Exercise: 'Exercise walkthrough',
	LandingConstructor: 'Site builder',
}

const My = {
	MySchools: 'My schools',
	MySchool: 'My school',
	MyPayments: 'My payment systems',
	MyPayment: 'My payment systems',
	MySubscription: 'My subscription',
	MySubscriptionSuccess: 'The subscription was successful',
	MySubscriptionFail: 'Subscription failed',
	MyPaymentsHistory: 'Payment history',
}

const LeadsUrls = {
	Leads: 'Leads/purchases',
}

const Product = {
	Products: 'Answer feed',
}

const Message = {
	Messages: 'Messages',
}

const Answer = {
	AnswersFeed: 'Answer feed',
	AnswersFeedGroup: 'Answer feed',
}

const Profile = {
	Profile: 'Profile',
}

const AudioCourse = {
	AudioCourse: ' Start speaking English in 30 hours',
}

const urlEn = {
	auth: Auth,
	main: MainUrls,
	student: StudentUrls,
	staff: StaffUrls,
	training: Training,
	my: My,
	leads: LeadsUrls,
	product: Product,
	message: Message,
	answer: Answer,
	profile: Profile,
	audioCourse: AudioCourse,
}

export default urlEn
