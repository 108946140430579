import { useMemo } from 'react'
import useSubscriptionsStore from '../../../zustand/store'

export function useGetTexts() {
	const tariffPlans = useSubscriptionsStore((store) => store.tariffPlans)

	return useMemo(
		function () {
			if (!tariffPlans) return null

			const currentPlan = tariffPlans.find((plan) => plan.isCurrentPlan)
			if (!currentPlan) return null

			return {
				planIconType: currentPlan.iconType,
				planName: currentPlan.name,
			}
		},
		[tariffPlans]
	)
}
