import PaymentsApiTypes from 'parts/requests/payments/paymentsApiTypes'
import PaymentTypes from 'parts/types/PaymentTypes'
import { getPlansScoreOrder } from './getIconType'

export function isMostExpensivePlan(
	thisTariffPlan: PaymentTypes.TariffPlan,
	tariffPlansRes: PaymentsApiTypes.GetTariffPlans
) {
	const plansScoreOrder = getPlansScoreOrder(tariffPlansRes)

	return plansScoreOrder[plansScoreOrder.length - 1].id === thisTariffPlan.id
}
