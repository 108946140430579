import $api from '../http'
import PaymentsApiTypes from './paymentsApiTypes'

const paymentsRequests = {
	// Оплата курса
	pay(dto: PaymentsApiTypes.PayDto) {
		return $api.post<PaymentsApiTypes.Pay>('/api/payment/training', dto)
	},

	// Получение массива оплат пользователя
	getPaymentsOfUser(userId: string | number) {
		return $api.get<PaymentsApiTypes.GetPaymentsOfUser>(
			'/api/payment/by-user/' + userId
		)
	},

	// Получение массива оплат всех пользователей
	getPayments(params?: PaymentsApiTypes.GetPaymentsParams) {
		let paramsStr = ''

		if (params && Object.keys(params).length) {
			let paramsParts: string[] = []

			if (params.filter) {
				for (let key in params.filter) {
					// @ts-ignore
					const value = params.filter[key]

					if (Array.isArray(value)) {
						value.forEach((value) => {
							paramsParts.push(`filter[${key}]=${value}`)
						})
					} else if (value) {
						paramsParts.push(`filter[${key}]=${value}`)
					}
				}
			}

			if (params.offset !== undefined) {
				paramsParts.push('offset=' + params.offset)
			}

			if (params.limit !== undefined) {
				paramsParts.push('limit=' + params.limit)
			}

			paramsStr += '?' + paramsParts.join('&')
		}

		return $api.get<PaymentsApiTypes.GetPayments>(
			'api/payment/transactions' + paramsStr
		)
	},
	// Получение тарифных планов школы
	getTariffPlans() {
		return $api.get<PaymentsApiTypes.GetTariffPlans>(
			'/api/payment/tariff-plans'
		)
	},
	// Оплата тарифа
	payTariffPlan(dto: PaymentsApiTypes.PayTariffPlanDto) {
		return $api.post<PaymentsApiTypes.PayTariffPlan>(
			'/api/payment/tariff-plans',
			dto
		)
	},
	// Отмена текущей подписки на тариф
	cancelCurrentTariffPlan(dto: PaymentsApiTypes.CancelCurrentTariffPlanDto) {
		return $api.post<PaymentsApiTypes.CancelCurrentTariffPlan>(
			'/api/payment/cancel-subscription',
			dto
		)
	},
	// Получение списка оплат тарифов
	getAdminSubscriptionsHistory() {
		return $api.get<PaymentsApiTypes.GetAdminSubscriptionsHistory>(
			'/api/payment/admin'
		)
	},
}

export default paymentsRequests
