import PaymentTypes from 'parts/types/PaymentTypes'
import { getRusNounByNumber } from 'parts/utils/string'
import { LeadsStatsStoreType } from '../storeTypes'

export function getRestrictions(
	restrObj: PaymentTypes.TariffPlanRestrictions
): LeadsStatsStoreType.Restrictions {
	return {
		// === Общие ограничения ===
		// Количество школ
		schools: {
			shortValue: restrObj.schools,
			fullValue: getSchoolsCountStr(restrObj.schools),
		},
		// Количество курсов. Если >= 1000000, то без ограничений.
		trainings: {
			shortValue:
				restrObj.trainings >= 1000000
					? 'Безлимит'
					: numToString(restrObj.trainings),
			fullValue: getTrainingsCountStr(restrObj.trainings),
		},
		// Количество новых учеников в месяц. Если 0, то недоступно. Если >= 1000000, то без ограничений.
		newStudentsInMonth: {
			shortValue:
				restrObj.newStudentsInMonth >= 1000000
					? 'Безлимит'
					: numToString(restrObj.newStudentsInMonth),
			fullValue: getStudentsCountStr(restrObj.newStudentsInMonth),
		},
		// Максимальное количество менеджеров. Если 0, то недоступно. Если >= 1000000, то без ограничений.
		maxManagers: {
			shortValue:
				restrObj.maxManagers >= 1000000
					? 'Безлимит'
					: numToString(restrObj.maxManagers),
			fullValue: getManagersCountStr(restrObj.maxManagers),
		},
		// Максимальное количество кураторов. Если 0, то недоступно. Если >= 1000000, то без ограничений.
		maxCourators: {
			shortValue:
				restrObj.maxCourators >= 1000000
					? 'Безлимит'
					: numToString(restrObj.maxCourators),
			fullValue: getCuratorsCountStr(restrObj.maxCourators),
		},
		// Подключение систем приема платежей
		ownPayments: {
			shortValue: restrObj.ownPayments,
			fullValue: restrObj.ownPayments
				? 'Интеграция с платежными сервисами'
				: '',
		},
		// Место для файлов в гигибайтах
		storageInGb: {
			shortValue: restrObj.storageInGb ? restrObj.storageInGb + 'Гб' : '',
			fullValue:
				'Места для загруженных файлов: ' + restrObj.storageInGb + 'Гб',
		},
		// === Модуль «Курсы» ===
		// Генерирование контента через искусственный интеллект
		aiGeneration: {
			shortValue: restrObj.aiGeneration,
			fullValue: restrObj.aiGeneration
				? 'Генерирование контента через искусственный интеллект'
				: '',
		},
		// Разделение на группы учеников
		groups: {
			shortValue: restrObj.groups,
			fullValue: restrObj.groups ? 'Разделение на группы учеников' : '',
		},
		// Конструктор одностраничников
		siteConstructor: {
			shortValue: restrObj.siteConstructor,
			fullValue: restrObj.siteConstructor
				? 'Конструктор одностраничников'
				: '',
		},
		// Шаблоны уроков
		lessonTemplate: {
			shortValue: restrObj.lessonTemplate,
			fullValue: restrObj.lessonTemplate ? 'Шаблоны уроков' : '',
		},
		// ЦРМ
		crm: {
			shortValue: restrObj.crm,
			fullValue: restrObj.crm ? 'ЦРМ' : '',
		},
		// === Модуль «Эфиры» ===
		// Модуль Live-уроки
		liveLessons: {
			shortValue: restrObj.liveLessons,
			fullValue: restrObj.liveLessons ? 'Модуль Live-уроки' : '',
		},
		// Сколько участников может быть на эфире
		liveMaxParticipants: {
			shortValue: restrObj.liveMaxParticipants,
			fullValue: getParticipantsCountStr(restrObj.liveMaxParticipants),
		},
		// Онлайн доска
		liveWhaiteboard: {
			shortValue: restrObj.liveWhaiteboard,
			fullValue: restrObj.liveWhaiteboard ? 'Онлайн доска' : '',
		},
		// Сохранение истории доски
		liveStoreWhiteboard: {
			shortValue: restrObj.liveStoreWhiteboard,
			fullValue: restrObj.liveStoreWhiteboard
				? 'Сохранение истории доски'
				: '',
		},
		// Стикеры для досок
		liveWhiteboardStickers: {
			shortValue: restrObj.liveWhiteboardStickers,
			fullValue: restrObj.liveWhiteboardStickers
				? 'Стикеры для досок'
				: '',
		},
		// Запись урока
		liveLessonRecord: {
			shortValue: restrObj.liveLessonRecord,
			fullValue: restrObj.liveLessonRecord ? 'Запись урока' : '',
		},
		// Онлайн чат
		liveChat: {
			shortValue: restrObj.liveChat,
			fullValue: restrObj.liveChat ? 'Онлайн чат' : '',
		},
		// Интеграция с YouTube
		liveYoutube: {
			shortValue: restrObj.liveYoutube,
			fullValue: restrObj.liveYoutube ? 'Интеграция с YouTube' : '',
		},
		// Интеграция с YouTube
		tenExercises: {
			shortValue: true,
			fullValue: '10 видов упражнений',
		},
		// Расписание
		schedule: {
			shortValue: true,
			fullValue: 'schedule',
		},
	}
}

function numToString(num: number) {
	if (num === 0) return ''
	return num.toString()
}

/**
 * Принимает количество доступных школ в тарифе и возвращает строку вида: 1 школа
 * @param schoolsNum — максимальное коилчество школ
 */
function getSchoolsCountStr(schoolsNum: number) {
	const schoolsWord = getRusNounByNumber(schoolsNum, 'школа', 'школы', 'школ')

	return schoolsNum + ' ' + schoolsWord
}

/**
 * Принимает количество доступных курсов в тарифе и возвращает строку вида: 1-10 курсов.
 * Если 0, то недоступно. Если >= 1000000, то без ограничений.
 * @param trainingsNum — максимальное количество курсов
 */
function getTrainingsCountStr(trainingsNum: number) {
	if (trainingsNum >= 1000000) {
		return 'Безлимит'
	}

	const schoolsWord = getRusNounByNumber(
		trainingsNum,
		'курс',
		'курса',
		'курсов'
	)

	return trainingsNum + ' ' + schoolsWord
}

/**
 * Принимает количество доступных учеников в тарифе и возвращает строку вида: 1000 учеников.
 * Если 0, то недоступно. Если >= 1000000, то без ограничений.
 * @param studentsNum — максимальное количество учеников
 */
function getStudentsCountStr(studentsNum: number) {
	if (studentsNum >= 1000000) {
		return 'Безлимит'
	}

	const studentsWord = getRusNounByNumber(
		studentsNum,
		'ученик',
		'ученика',
		'учеников'
	)

	return studentsNum + ' ' + studentsWord
}

/**
 * Принимает количество доступных менеджеров в тарифе и возвращает строку вида: 1000 менеджеров.
 * Если 0, то недоступно. Если >= 1000000, то без ограничений.
 * @param managersNum — максимальное количество менеджеров
 */
function getManagersCountStr(managersNum: number) {
	if (managersNum >= 1000000) {
		return 'Безлимит'
	}

	const managersWord = getRusNounByNumber(
		managersNum,
		'менеджер',
		'менеджера',
		'менеджеров'
	)

	return managersNum + ' ' + managersWord
}

/**
 * Принимает количество доступных кураторов в тарифе и возвращает строку вида: 1000 кураторов.
 * Если 0, то недоступно. Если >= 1000000, то без ограничений.
 * @param curatorsNum — максимальное количество кураторов
 */
function getCuratorsCountStr(curatorsNum: number) {
	if (curatorsNum >= 1000000) {
		return 'Безлимит'
	}

	const curatorsWord = getRusNounByNumber(
		curatorsNum,
		'куратор',
		'куратора',
		'кураторов'
	)

	return curatorsNum + ' ' + curatorsWord
}

/**
 * Принимает количество участинков, которые могут одновременно находиться в эфире и возвращает строку вида: 30 участников.
 * @param participantsNum — максимальное количество участников
 */
function getParticipantsCountStr(participantsNum: number) {
	const participantsWord = getRusNounByNumber(
		participantsNum,
		'участник',
		'участника',
		'участников'
	)

	return participantsNum + ' ' + participantsWord
}
