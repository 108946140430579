import { LeadsStatsStoreType } from '../../zustand/storeTypes'
import TariffSign from '../../common/TariffSign/TariffSign'
import s from './CardTop.module.scss'

type CardTopProps = {
	// Данные тарифа
	tariffPlan: LeadsStatsStoreType.TariffPlan
}

function CardTop(props: CardTopProps) {
	const { tariffPlan } = props

	return (
		<div>
			<div className={s.topWrapper}>
				<TariffSign
					type={tariffPlan.iconType as any}
					current={tariffPlan.isCurrentPlan}
				/>
				<p className={s.planName}>{tariffPlan.name}</p>
			</div>
			<p className={s.planDescription}>{tariffPlan.description}</p>
			<div className={s.hr} />
			<Restrictions tariffPlan={tariffPlan} />
		</div>
	)
}

export default CardTop

type RestrictionsProps = {
	tariffPlan: LeadsStatsStoreType.TariffPlan
}

function Restrictions(props: RestrictionsProps) {
	const { restrictions, isFreePlan } = props.tariffPlan

	return (
		<div className={s.restrictions}>
			<p className={s.restriction}>
				<img src="/images/signs/checkInPayments.svg" alt="sign" />
				{restrictions.schools.fullValue}
			</p>
			<p className={s.restriction}>
				<img src="/images/signs/checkInPayments.svg" alt="sign" />
				{restrictions.trainings.fullValue}
			</p>
			<p className={s.restriction}>
				<img src="/images/signs/checkInPayments.svg" alt="sign" />
				{restrictions.newStudentsInMonth.fullValue}
			</p>
			{restrictions.maxManagers.shortValue && (
				<p className={s.restriction}>
					<img src="/images/signs/checkInPayments.svg" alt="sign" />
					{restrictions.maxManagers.fullValue}
				</p>
			)}
			{restrictions.maxCourators.shortValue && (
				<p className={s.restriction}>
					<img src="/images/signs/checkInPayments.svg" alt="sign" />
					{restrictions.maxCourators.fullValue}
				</p>
			)}
			{restrictions.ownPayments.shortValue && (
				<p className={s.restriction}>
					<img src="/images/signs/checkInPayments.svg" alt="sign" />
					{restrictions.ownPayments.fullValue}
				</p>
			)}
			{restrictions.storageInGb.shortValue && (
				<p className={s.restriction}>
					<img src="/images/signs/checkInPayments.svg" alt="sign" />
					{restrictions.storageInGb.fullValue}
				</p>
			)}
			{!isFreePlan && <PresentBlock />}
			{!isFreePlan && <ExtraModules />}
		</div>
	)
}

function PresentBlock() {
	return (
		<div className={s.gift}>
			<img
				src="/images/signs/GiftTwoTone.svg"
				alt="gift"
				className={s.giftIcon}
			/>
			<span className={s.giftText}>В подарок сайт для вашего курса</span>
		</div>
	)
}

function ExtraModules() {
	return (
		<div className={s.extraModules}>
			<p className={s.extraModule}>Модуль live-уроки</p>
			<p className={s.extraModule}>Модуль Курсы</p>
		</div>
	)
}
