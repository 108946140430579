import { Button } from 'antd'
import React from 'react'
import useSystemStore from 'parts/systemStore/systemStore'
import ContentWrapper from '../ContentWrapper/ContentWrapper'
import { getNextPaymentSum } from './fn/getNextPaymentSum'

function RightPart() {
	const userTariffPlan = useSystemStore((store) => store.user.tariffPlan)

	if (userTariffPlan.isTrialPlan) {
		return (
			<Button type="primary" block>
				Выбрать план
			</Button>
		)
	}

	return (
		<ContentWrapper
			topText="Сумма оплаты"
			mainText={getNextPaymentSum(userTariffPlan)}
		/>
	)
}

export default RightPart
