import { useEffect } from 'react'
import useSystemStore from 'parts/systemStore/systemStore'
import { paymentsQuery } from 'parts/requests/payments/paymentsQuery'
import useSubscriptionsStore from '../store'
import { convertTariffPlansResToStoreTariffPlans } from './convertTariffPlans'

export function useFillStore() {
	const userTariffPlan = useSystemStore((store) => store.user.tariffPlan)
	const updateSubscriptionsStore = useSubscriptionsStore(
		(store) => store.updateStore
	)

	const { data: tariffPlansRes } = paymentsQuery.getTariffPlans.useQuery()

	useEffect(
		function () {
			if (!tariffPlansRes?.data) return

			const tariffPlansForStore = convertTariffPlansResToStoreTariffPlans(
				userTariffPlan,
				tariffPlansRes.data
			)

			updateSubscriptionsStore({
				currentPlanId: userTariffPlan.id,
				rowTariffPlans: tariffPlansRes.data,
				tariffPlans: tariffPlansForStore,
			})
		},
		[tariffPlansRes]
	)
}
