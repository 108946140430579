import React from 'react'
import TariffSign from '../../common/TariffSign/TariffSign'
import ContentWrapper from '../ContentWrapper/ContentWrapper'
import { useGetTexts } from './fn/getPanelTexts'

function LeftPart() {
	const texts = useGetTexts()
	if (!texts) return null

	return (
		<ContentWrapper
			icon={<TariffSign type={texts.planIconType as any} current />}
			topText="Ваш текущий план"
			mainText={texts.planName}
		/>
	)
}

export default LeftPart
