import PaymentTypes from 'parts/types/PaymentTypes'
import { LeadsStatsStoreType } from '../storeTypes'

export function getEurPricesObj(tariffRes: PaymentTypes.TariffPlan) {
	const resultPricesObj: LeadsStatsStoreType.Prices = {}

	if (!tariffRes.isPaid) {
		return resultPricesObj
	}

	tariffRes.prices
		.filter(
			(price) =>
				price.currency.toUpperCase() === PaymentTypes.Currency.EUR
		)
		.forEach((price) => {
			const currencySign =
				// @ts-ignore
				PaymentTypes.Currencies[price.currency.toUpperCase()].sign

			if (price.interval === 'month') {
				resultPricesObj.monthlyPrice = price.amount / 100
				resultPricesObj.currency = currencySign
			} else {
				resultPricesObj.annuallyPrice = price.amount / 100
				resultPricesObj.currency = currencySign
			}
		})

	return resultPricesObj
}
