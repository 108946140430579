import React from 'react'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import NoGroupsScreen from 'widgets/groups/trainingGroupsTable/GroupsTable/NoGroups'
import { AddEmployeeModalController } from '../staffTable/addEmployeeModal/AddEmployeeModalController'
import { StaffTableController } from '../staffTable/StaffTableController'

function StaffArticle() {
	const staffTableRouter = StaffTableController.router()

	const inCenter =
		staffTableRouter.status === 'loading' ||
		staffTableRouter.status == 'noStaff' ||
		staffTableRouter.status == 'error'

	return (
		<ArticleContentWrapper
			center={inCenter}
			innerWrapperWidth={inCenter ? 'small' : 'full'}
		>
			{staffTableRouter.status == 'loading' && staffTableRouter.element}
			{staffTableRouter.status == 'noStaff' && <NoGroupsScreen />}
			{staffTableRouter.status == 'hasStaff' && staffTableRouter.element}
			{AddEmployeeModalController.element}
		</ArticleContentWrapper>
	)
}

export default StaffArticle
