import { MenuFoldOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { useTranslation } from 'react-i18next'
import RestrictionsService from 'parts/services/RestrictionsService'
import {
	useGetHandleMenuClick,
	useGetMenuItems,
	useGetSelectedMenuItems,
} from './fn/getMenuItems'
import { useGetUnansweredFeedCount } from './fn/getUnansweredFeedCount'
import { useGetDefaultSelectedItemKey } from './fn/selectedItems'
import './MainMenuItems.scss'

// Главное меню приложения (слева)
function MainMenuItems() {
	const { t } = useTranslation()
	useGetUnansweredFeedCount()

	const menuItems = useGetMenuItems()
	const defaultSelectedItem = useGetDefaultSelectedItemKey()
	const selectedItem = useGetSelectedMenuItems()

	const canUseLiveLessons = RestrictionsService.useCanUseLiveLessons()
	const canUseCRM = RestrictionsService.useCanUseCRM()
	const onMenuClick = useGetHandleMenuClick(canUseLiveLessons, canUseCRM)

	const userRoles = useGetUserRole()
	if (!userRoles.rolesGot) return null

	return (
		<Menu
			mode="inline"
			defaultSelectedKeys={defaultSelectedItem}
			className="main-menu-list"
			onClick={onMenuClick}
			expandIcon={<MenuFoldOutlined />}
			items={menuItems}
			selectedKeys={selectedItem}
		/>
	)
}

export default MainMenuItems
