import React from 'react'
import { Space } from 'antd'
import { useTranslation } from 'react-i18next'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import AddStudentButton from '../AddStudentButton/AddStudentButton'
import { StudentsTableAdminController } from '../studentsTableAdmin/StudentsTableAdmin/StudentsTableAdminController'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import { AddStudentModalController } from '../AddStudentModal/AddStudentModalController'
import RowsContainer from 'ui/RowsContainer/RowsContainer'
import { StudentsStatsAdminController } from '../StudentsStats/StudentsStatsAdminController'
import s from './AdminStudentsArticle.module.scss'
import UsersPagination from '../UsersPagination/UsersPagination'

/** Статья со списком учеников */
function AdminStudentsArticle() {
	const studentsStatsRouter = StudentsStatsAdminController.router()
	const studentsTableRouter = StudentsTableAdminController.router()

	return (
		<ArticleContentWrapper innerWrapperWidth="big">
			<Space direction="vertical" size={30} style={{ width: '100%' }}>
				<RowsContainer>
					{studentsStatsRouter.element}
					{studentsTableRouter.status == 'noStudents' && (
						<div className={s.noStudentsWrapper}>
							<NoStudents />
						</div>
					)}
					{studentsTableRouter.status == 'hasStudents' &&
						studentsTableRouter.element}
				</RowsContainer>
				<UsersPagination />
				{AddStudentModalController.element}
			</Space>
		</ArticleContentWrapper>
	)
}

export default AdminStudentsArticle

// Экран про отсутствие учеников
function NoStudents() {
	const { t } = useTranslation()

	return (
		<EmptyBlock
			text={
				<>
					{t('students.noStudentsScreenFirstTextLine')}
					<br />
					{t('students.noStudentsScreenSecondTextLine')}
				</>
			}
			button={<AddStudentButton />}
		/>
	)
}
