import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import useMenuStore from 'app/menu/zustand/menuStore'
import { useGetUserRole, UserRolesObj } from 'parts/utils/hooks/hooks'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import RestrictionsService from 'parts/services/RestrictionsService'
import {
	adminMenuItems,
	curatorMenuItems,
	managerMenuItems,
	studentMenuItems,
} from './menuItems'

/** Возвращает массив пунктов меню */
export function useGetMenuItems() {
	const userRoles = useGetUserRole()
	const unansweredFeedCount = useMenuStore(
		(store) => store.unansweredFeedCount
	)

	const canUseLiveLessons = RestrictionsService.useCanUseLiveLessons()
	const canUseCRM = RestrictionsService.useCanUseCRM()

	if (!userRoles.rolesGot) return []

	return getMenuItems(
		userRoles,
		canUseLiveLessons,
		canUseCRM,
		unansweredFeedCount
	)
}

/** Возвращает массив адресов, которые должны быть выделены в меню исходя из текущего адреса */
export function useGetSelectedMenuItems(): string[] {
	const { pathname } = location
	const userRoles = useGetUserRole()

	const unansweredFeedCount = useMenuStore(
		(store) => store.unansweredFeedCount
	)

	const canUseLiveLessons = RestrictionsService.useCanUseLiveLessons()
	const canUseCRM = RestrictionsService.useCanUseCRM()

	const menuItems = getMenuItems(
		userRoles,
		canUseLiveLessons,
		canUseCRM,
		unansweredFeedCount
	)

	const item = menuItems.find((item) => {
		// @ts-ignore
		return pathname.startsWith(item.key)
	})

	if (!item) return ['']

	// @ts-ignore
	return [item.key]
}

/**
 * Возвращает пункты меню исходя из роли пользователя
 * @param userRoles — объект с ролью пользователя
 * @param unansweredFeedCount — количество неотвеченных ответов
 */
export function getMenuItems(
	userRoles: UserRolesObj,
	canUseLiveLessons: boolean,
	canUseCRM: boolean,
	unansweredFeedCount?: number
): ItemType[] {
	if (userRoles.isAdmin) {
		return adminMenuItems(
			canUseLiveLessons,
			canUseCRM,
			Number(unansweredFeedCount)
		)
	} else if (userRoles.isManager) {
		return managerMenuItems(
			canUseLiveLessons,
			canUseCRM,
			Number(unansweredFeedCount)
		)
	} else if (userRoles.isCurator) {
		return curatorMenuItems(
			canUseLiveLessons,
			canUseCRM,
			Number(unansweredFeedCount)
		)
	} else {
		return studentMenuItems()
	}
}

/** Обработчик щелчка по пункту меню */
export function useGetHandleMenuClick(
	canUseLiveLessons: boolean,
	canUseCRM: boolean
) {
	const navigate = useNavigate()
	const userRoles = useGetUserRole()

	return useCallback(
		function (e: any) {
			const menuItems = getMenuItems(
				userRoles,
				canUseLiveLessons,
				canUseCRM,
				0
			)

			// @ts-ignore
			const menuItem = menuItems.find((item) => item.key === e.key)
			if (!menuItem) return

			// @ts-ignore
			navigate(menuItem.key)

			// Закрыть телефонное меню
			useMenuStore.getState().updateStore({ isMobileMenuOpen: false })
		},
		[userRoles]
	)
}
