import React from 'react'
import { Switch } from 'antd'
import useSubscriptionsStore from '../../zustand/store'
import { useGetOnSwitchPlansTableVisibility } from './fn/onSwitchPlansTableVisibility'
import s from './ShowCompareSwitcher.module.scss'

function ShowCompareSwitcher() {
	const showPlansComparisonTable = useSubscriptionsStore(
		(store) => store.showPlansComparisonTable
	)
	const onChange = useGetOnSwitchPlansTableVisibility()

	return (
		<div className={s.opportunitiesMainWrapper}>
			<div className={s.opportunities}>
				<p className={s.opportunitiesText}>
					Все возможности тарифных планов
				</p>
				<Switch
					onChange={onChange}
					checked={showPlansComparisonTable}
				/>
			</div>
		</div>
	)
}

export default ShowCompareSwitcher
