import useSubscriptionsStore from '../../../zustand/store'

export function useGetOnSwitchPlansTableVisibility() {
	const updateSubscriptionsStore = useSubscriptionsStore(
		(store) => store.updateStore
	)

	return function (checked: boolean) {
		updateSubscriptionsStore({
			showPlansComparisonTable: checked,
		})
	}
}
