import React from 'react'
import cn from 'classnames'
import { UTable, UTableBCell, UTableBody, UTableBRow } from 'ui/UTable/UTable'
import PaymentTypes from '../../../../../../parts/types/PaymentTypes'
import TariffSign from '../../common/TariffSign/TariffSign'
import { getRestrictions } from '../../zustand/fn/getRestrictions'
import useSubscriptionsStore from '../../zustand/store'
import { LeadsStatsStoreType } from '../../zustand/storeTypes'
import { prepareValuesForCells } from './fn/prepareValuesForCells'
import s from './PlansComparisonTable.module.scss'

function PlansComparisonTable() {
	const showPlansComparisonTable = useSubscriptionsStore(
		(store) => store.showPlansComparisonTable
	)
	const rowTariffPlans = useSubscriptionsStore(
		(store) => store.rowTariffPlans
	)
	let rowTariffPlansWithoutTrial: PaymentTypes.TariffPlan[] = []

	if (rowTariffPlans) {
		rowTariffPlansWithoutTrial = rowTariffPlans.filter(
			(tariff) => !tariff.isHidden
		)
	}

	const tariffPlans = useSubscriptionsStore((store) => store.tariffPlans)

	if (!showPlansComparisonTable || !tariffPlans || !rowTariffPlans)
		return null

	const tariffPlansRestrictions = rowTariffPlansWithoutTrial.map(
		(rowTariffPlan) => {
			return getRestrictions(rowTariffPlan.restrictions)
		}
	)

	const selectedColumnNum = tariffPlans.findIndex(
		(t) => t.isMostExpensivePlan
	)

	return (
		<UTable>
			<UTableBody>
				<PlansRow tariffPlans={tariffPlans} />
				<HeaderRow text="Общее" colLength={tariffPlans.length} />
				<CommonPropsRows
					selectedColumnNum={selectedColumnNum}
					tariffPlansRestrictions={tariffPlansRestrictions}
				/>
				<HeaderRow text="Модуль курсы" colLength={tariffPlans.length} />
				<TrainingPropsRows
					selectedColumnNum={selectedColumnNum}
					tariffPlansRestrictions={tariffPlansRestrictions}
				/>
				<HeaderRow
					text="Модуль Live-уроки"
					colLength={tariffPlans.length}
				/>
				<LivePropsRows
					selectedColumnNum={selectedColumnNum}
					tariffPlansRestrictions={tariffPlansRestrictions}
				/>
				<HeaderRow text="" colLength={tariffPlans.length} />
				<PricesRow selectedColumnNum={selectedColumnNum} />
			</UTableBody>
		</UTable>
	)
}

export default PlansComparisonTable

type PlansRowProps = {
	tariffPlans: LeadsStatsStoreType.TariffPlan[]
}

function PlansRow(props: PlansRowProps) {
	const { tariffPlans } = props

	return (
		<UTableBRow>
			<UTableBCell>
				<p className={cn(s.headerCellText, s.headerCellGrayText)}>
					Тарифный план
				</p>
			</UTableBCell>
			{tariffPlans.map((tariffPlan) => {
				return (
					<UTableBCell
						key={tariffPlan.id}
						extraClass={tariffPlan.isCurrentPlan && s.selectedCell}
					>
						<div className={s.headerCellWithSign}>
							<TariffSign
								type={tariffPlan.iconType as any}
								extraClass={s.tariffSign}
							/>
							<p
								className={cn(
									s.headerCellText,
									s.headerCellBlackText
								)}
							>
								{tariffPlan.name}
							</p>
						</div>
					</UTableBCell>
				)
			})}
		</UTableBRow>
	)
}

type HeaderRowProps = {
	text: string
	colLength: number
}

function HeaderRow(props: HeaderRowProps) {
	const { text, colLength } = props

	return (
		<UTableBRow>
			<UTableBCell colSpan={colLength + 1} extraClass={s.transparentCell}>
				<p className={s.headerBigText}>{text}</p>
			</UTableBCell>
		</UTableBRow>
	)
}

type CommonPropsRowsProps = {
	selectedColumnNum: number
	tariffPlansRestrictions: LeadsStatsStoreType.Restrictions[]
}

function CommonPropsRows(props: CommonPropsRowsProps) {
	const { selectedColumnNum, tariffPlansRestrictions } = props

	return (
		<>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Количество школ"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'schools'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Курсов"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'trainings'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Новых учеников в месяц"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'newStudentsInMonth'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Менеджеров"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'maxManagers'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Кураторы"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'maxCourators'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Подключение систем приема платежей"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'ownPayments'
				)}
			/>
		</>
	)
}

type TrainingPropsRowsProps = {
	selectedColumnNum: number
	tariffPlansRestrictions: LeadsStatsStoreType.Restrictions[]
}

function TrainingPropsRows(props: TrainingPropsRowsProps) {
	const { selectedColumnNum, tariffPlansRestrictions } = props

	return (
		<>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Расписание"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'schedule'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="10 видов упражнений"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'tenExercises'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Генерация AI упражнений"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'aiGeneration'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Группы для курсов"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'groups'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Конструктор сайта для курса"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'siteConstructor'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Шаблоны уроков"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'lessonTemplate'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Интеграция с платежными сервисами"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'ownPayments'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Файловое Хранилище"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'storageInGb'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Встроенная CRM система"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'crm'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Группы студентов"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'groups'
				)}
			/>
		</>
	)
}

type LivePropsRowsProps = {
	selectedColumnNum: number
	tariffPlansRestrictions: LeadsStatsStoreType.Restrictions[]
}

function LivePropsRows(props: LivePropsRowsProps) {
	const { selectedColumnNum, tariffPlansRestrictions } = props

	return (
		<>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Онлайн доска"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'liveWhaiteboard'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Сохранении истории доски"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'liveStoreWhiteboard'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Стикеры для досок"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'liveWhiteboardStickers'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Запись урока"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'liveLessonRecord'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Онлайн чат"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'liveChat'
				)}
			/>
			<TableRow
				selectedColumnNum={selectedColumnNum}
				header="Интеграция с YouTube"
				cellsValues={prepareValuesForCells(
					tariffPlansRestrictions,
					'liveYoutube'
				)}
			/>
		</>
	)
}

type TableRowProps = {
	header: string
	cellsValues: (string | number | boolean)[]
	selectedColumnNum: number
}

function TableRow(props: TableRowProps) {
	const { header, cellsValues, selectedColumnNum } = props

	return (
		<UTableBRow>
			<UTableBCell>
				<p className={cn(s.cellText, s.cellTextLeft)}>{header}</p>
			</UTableBCell>
			{cellsValues.map((cellVal, i) => {
				return (
					<UTableBCell
						key={i}
						extraClass={selectedColumnNum === i && s.selectedCell}
					>
						<p className={cn(s.cellText, s.cellTextValue)}>
							<CellSignValue value={cellVal} />
						</p>
					</UTableBCell>
				)
			})}
		</UTableBRow>
	)
}

type CellSignProps = {
	value: string | number | boolean
}

function CellSignValue(props: CellSignProps) {
	const { value } = props

	if (value === true) {
		return <img src="/images/signs/CheckCircleTwoToneBlue.svg" alt="yes" />
	} else if (value === false) {
		return <img src="/images/signs/CloseCircleOutlinedGray.svg" alt="no" />
	}

	return <>{value}</>
}

type PricesRowProps = {
	selectedColumnNum: number
}

function PricesRow(props: PricesRowProps) {
	const { selectedColumnNum } = props

	const tariffPlans = useSubscriptionsStore((store) => store.tariffPlans)
	const paymentDurationValue = useSubscriptionsStore(
		(store) => store.paymentDurationValue
	)

	if (!tariffPlans) return null
	const prices = tariffPlans.map((tariff) => {
		let price =
			paymentDurationValue === 'year'
				? tariff.prices.annuallyPrice
				: tariff.prices.monthlyPrice

		if (tariff.prices.currency) {
			// @ts-ignore
			price += tariff.prices.currency
		}

		return price || 'Бесплатно'
	})

	return (
		<TableRow
			selectedColumnNum={selectedColumnNum}
			header="Онлайн доска"
			cellsValues={prices}
		/>
	)
}
