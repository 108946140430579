import React from 'react'
import PlansComparisonTable from '../PlansComparisonTable/PlansComparisonTable'
import ShowCompareSwitcher from '../ShowCompareSwitcher/ShowCompareSwitcher'
import s from './PlansComparison.module.scss'

function PlansComparison() {
	return (
		<div className={s.wrapper}>
			<ShowCompareSwitcher />
			<PlansComparisonTable />
		</div>
	)
}

export default PlansComparison
