import React from 'react'
import Card from '../Card/Card'
import useSubscriptionsStore from '../../zustand/store'
import s from './Cards.module.scss'

function Cards() {
	const tariffPlans = useSubscriptionsStore((store) => store.tariffPlans)

	if (!tariffPlans) return null

	const cardsWithoutTrialPlan = tariffPlans.filter(
		(plan) => !plan.isTrialPlan
	)

	return (
		<div className={s.wrapper}>
			{cardsWithoutTrialPlan.map((tariffPlan) => {
				return <Card tariffPlan={tariffPlan} key={tariffPlan.id} />
			})}
		</div>
	)
}

export default Cards
