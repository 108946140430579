const studentsRu = {
	// Кнопка добавления ученика
	addStudentButton: 'Добавить',

	// МОДАЛЬНОЕ ОКНО ДОБАВЛЕНИЯ УЧЕНИКА
	// Заголовок модального окна
	addStudentModalTitle: 'Добавление ученика',
	// Заголовок блока «Личные данные»
	addStudentModalPersonalBlockHeader: 'Личные данные',
	// Подпись поля «Почта»
	addStudentModalFieldEmailLabel: 'Почта',
	// Сообщение об ошибке при незаполненной почте
	addStudentModalFieldEmailRequiredError: 'Укажите почту',
	// Подпись поля «Почта»
	addStudentModalFieldPhoneLabel: 'Телефон',
	// Подпись поля «Имя»
	addStudentModalFieldNameLabel: 'Имя',
	// Подпись поля «Фамилия»
	addStudentModalFieldSurnameLabel: 'Фамилия',
	// Подпись флага «Выдать доступ в личный кабинет»
	addStudentModalFieldGetAccessText: 'Выдать доступ в личный кабинет',
	// Текст на кнопке сохранения формы добавления ученика
	addStudentModalOnButton: 'Сохранить',
	// Сообщение про ошибку при создании оплаты
	addStudentModalManualPaymentReq: 'Не удалось создать оплату',
	// Сообщение про ошибку про добавление ученика куратору
	addStudentModalAddStudentToCuratorReq:
		'Не удалось добавить ученика куратору',
	// Сообщение про ошибку при запросе на отправку ученику доступа к личному кабинету
	addStudentModalSendAccessReq: 'Не удалось добавить ученика',

	// ФОРМА ЗАКАЗА
	// Заголовок блока «Покупка»
	purchaseFormPersonalBlockHeader: 'Покупка',
	// Подпись поля с выбора группы
	purchaseFormFieldGroupLabel: 'Группа',
	// Ошибка про обязательное заполнение поля выбора группы
	purchaseFormFieldGroupRequiredError: 'Укажите группу',
	// Подпись поля даты покупки
	purchaseFormFieldPurchaseDateLabel: 'Дата покупки',
	// Ошибка про обязательное заполнение поля выбора группы
	purchaseFormFieldPurchaseDateRequiredError: 'Укажите дату покупки',
	// Подпись поля способа оплаты
	purchaseFormFieldPaymentSystemLabel: 'Способ оплаты:',
	// Ошибка про обязательное заполнение поля способа оплаты
	purchaseFormFieldPaymentSystemRequiredError: 'Укажите способ оплаты',
	// Подпись выпадающего списка выбора валюты
	purchaseFormFieldCurrencyLabel: 'Валюта',
	// Ошибка про обязательное поле выбора валюты
	purchaseFormFieldCurrencyRequiredError: 'Укажите валюту',
	// Подпись поля стоимости
	purchaseFormFieldAmountLabel: 'Стоимость',
	// Ошибка про обязательное поле стоимости
	purchaseFormFieldAmountRequiredError: 'Укажите стоимость',
	// Подпись выпадающего списка выбора куратора
	purchaseFormFieldCuratorLabel: 'Куратор',
	// Невыбранный пункт выпадающего списка выбора куратора
	purchaseFormFieldCuratorEmptyItem: 'Не выбран',
	// Кнопка удаления покупки
	purchaseFormDeletePurchaseButton: 'Удалить покупку',
	// Кнопка добавления покупки
	purchaseFormAddPurchaseButton: 'Добавить покупку',

	// ЭКРАН ПРО ОТСУТСТВИЕ УЧЕНИКОВ
	// Первая строка сообщения про отсутствие учеников
	noStudentsScreenFirstTextLine: 'Нет ни одного ученика.',
	// Вторая строка сообщения про отсутствие учеников
	noStudentsScreenSecondTextLine: 'Начните создание прямо сейчас!',

	// СТАТИСТИКА ПО УЧЕНИКАМ
	// Заголовок статистики по общему количеству учеников
	studentsStatsTotalStudentsHeader: 'Количество учеников (всего)',
	// Заголовок статистики по количеству новых учеников
	studentsStatsTodayStudentsHeader: 'Новые ученики (за сегодня)',

	// ТАБЛИЦА УЧЕНИКОВ
	// Статус Ученик активирован
	studentsTableStudentActivated: 'Активирован',
	// Статус Ученик не активирован
	studentsTableStudentNotActivated: 'Не активирован',
	// Заголовок ряда Имя и фамилия
	studentsTableHeaderNameAndSurname: 'Имя и фамилия',
	// Заголовок ряда Почта
	studentsTableHeaderEmail: 'Почта',
	// Заголовок ряда Телефон
	studentsTableHeaderPhone: 'Телефон',
	// Заголовок ряда Страна
	studentsTableHeaderCountry: 'Страна',
	// Заголовок ряда Статус
	studentsTableHeaderStatus: 'Статус',
}

export default studentsRu
