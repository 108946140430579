import PaymentTypes from 'parts/types/PaymentTypes'

export function getNextPaymentSum(userTariffPlan: PaymentTypes.TariffPlan) {
	const { interval, prices } = userTariffPlan

	const price = prices.find((price) => price.interval === interval)
	if (!price) return ''

	const { amount } = price
	// @ts-ignore
	const currency = PaymentTypes.Currencies[price.currency.toUpperCase()].sign

	return amount / 100 + currency
}
