import { Navigate, Route } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import EntityTypes from 'parts/types/EntityTypes'
import AnswersFeedPage from '../../pages/answersFeed/page/AnswersFeedPage/AnswersFeedPage'
import { LiveRoomsPagesWrapper } from '../../pages/liveLessons/liveRoomsPagesWrapper/LiveRoomsAdminPage/LiveRoomsPagesWrapper'
import LiveRoomsArticle from '../../pages/liveLessons/rooms/LiveRoomsArticle/LiveRoomsArticle'
import TrainingArticleForAdmin from '../../pages/training/admin/articles/TrainingArticleForAdmin/TrainingArticleForAdmin'
import TrainingStaffPage from '../../pages/training/admin/TrainingPage/TrainingStaffPage'
import {
	getLandingRoute,
	getLiveLessonEntranceRoute,
	getSuccessAndFailRoutes,
	RouterVarNames,
} from './special/otherRoutes'
import ErrorPage from 'pages/duty/ErrorPage/ErrorPage'
import ProtectedRoute from 'ui/ProtectedRoute/ProtectedRoute'
import AppLayout from '../AppLayout/AppLayout'
import MainPart from '../MainPart/MainPart'
import MyPayments from 'pages/my/admin/myPaymentsAdmin/MyPayments/MyPayments'
import MySchoolsArticle from 'pages/my/admin/mySchoolsAdmin/MySchoolsArticle/MySchoolsArticle'
import ProfilePage from 'pages/profile/ProfilePage/ProfilePage'
import GroupPage from 'pages/group/groupPage/GroupPage/GroupPage'
import GroupsArticle from 'pages/training/admin/articles/groups/GroupsArticle/GroupsArticle'
import ConditionsArticle from 'pages/group/conditions/ConditionsArticle/ConditionsArticle'
import MyPageManagerCuratorStudent from 'pages/my/managerCuratorStudent/MyPageManagerCuratorStudent/MyPageManagerCuratorStudent'
import TrainingsManagerCuratorPage from 'pages/trainings/managerCurator/TrainingsManagerCuratorPage/TrainingsManagerCuratorPage'
import LeadsPage from 'pages/leads/LeadsPage/LeadsPage'
import AdminStudentsPage from 'pages/students/admin/AdminStudentsPage/AdminStudentsPage'
import StaffArticle from 'pages/staff/StaffPage/StaffPage'
import LessonAdminPage from 'pages/lesson/admin/page/LessonPage/LessonAdminPage'
import GroupStaffArticle from 'pages/group/groupStaff/StaffArticle/GroupStaffArticle'
import StudentWrapArticle from 'pages/student/main/StudentWrapArticle/StudentWrapArticle'
import StudentProfileArticle from 'pages/student/profile/StudentProfileArticle/StudentProfileArticle'
import StudentOrdersArticle from 'pages/student/order/OrdersArticle/StudentOrdersArticle'
import StudentVisitsArticle from 'pages/student/visits/StudentVisitsArticle/StudentVisitsArticle'
import StudentHistoryArticle from 'pages/student/history/StudentHistoryArticle/StudentHistoryArticle'
import EmployeePage from 'pages/employee/main/EmployeeWrapArticle/EmployeePage'
import EmployeeProfileArticle from 'pages/employee/profile/EmployeeProfileArticle/EmployeeProfileArticle'
import EmployeeGroupsArticle from 'pages/employee/groups/EmployeeGroupsArticle/EmployeeGroupsArticle'
import { LiveLessonPage } from 'pages/liveLessons/live/LiveLessonPage'
import FilesPage from 'pages/files/FilesPage'
import OpenLessonsArticle from 'pages/group/openLessons/OpenLessonsArticle/OpenLessonsArticle'

export default function getManagerRouter(user: null | EntityTypes.User) {
	return (
		<Route>
			{getSuccessAndFailRoutes()}
			{getLandingRoute()}
			{getAppRouterWithLive(user)}
			{getLiveLessonEntranceRoute()}
		</Route>
	)
}

function getAppRouterWithLive(user: null | EntityTypes.User) {
	return (
		<Route>
			<Route
				path={AppUrls.LiveLesson(':' + RouterVarNames.LiveLessonId).url}
				element={
					<ProtectedRoute
						isAllowed={!!user}
						redirectPath={AppUrls.Login().url}
					>
						<LiveLessonPage />
					</ProtectedRoute>
				}
			/>
			{getAppRoutes(user)}
		</Route>
	)
}

function getAppRoutes(user: null | EntityTypes.User) {
	return (
		<Route
			element={
				<ProtectedRoute
					isAllowed={!!user}
					redirectPath={AppUrls.Login().url}
				>
					<AppLayout />
				</ProtectedRoute>
			}
		>
			<Route element={<MainPart />}>
				<Route element={<MyPageManagerCuratorStudent />}>
					<Route
						path={AppUrls.MySchools().url}
						element={<MySchoolsArticle />}
					/>
					<Route
						path={AppUrls.MyPayments().url}
						element={<MyPayments />}
					/>
				</Route>
				<Route
					path={AppUrls.Profile().url + '/*'}
					element={<ProfilePage />}
				/>
				<Route path={AppUrls.MyFiles().url} element={<FilesPage />} />
				<Route element={<LiveRoomsPagesWrapper />}>
					<Route
						path={AppUrls.LiveRooms().url}
						element={<LiveRoomsArticle />}
					/>
				</Route>
				<Route
					path={AppUrls.Trainings().url}
					element={<TrainingsManagerCuratorPage />}
				/>
				<Route element={<TrainingStaffPage />}>
					<Route
						path={
							AppUrls.Training(':' + RouterVarNames.TrainingId)
								.url
						}
						element={<TrainingArticleForAdmin />}
					/>
					<Route
						path={
							AppUrls.Training_Groups(
								':' + RouterVarNames.TrainingId
							).url
						}
						element={<GroupsArticle />}
					/>
				</Route>
				<Route element={<GroupPage />}>
					<Route
						path={
							AppUrls.Training_GroupConditions(
								':' + RouterVarNames.TrainingId,
								':' + RouterVarNames.GroupId
							).url
						}
						element={<ConditionsArticle />}
					/>
					<Route
						path={
							AppUrls.Training_GroupCurators(
								':' + RouterVarNames.TrainingId,
								':' + RouterVarNames.GroupId
							).url
						}
						element={<GroupStaffArticle />}
					/>
					<Route
						path={
							AppUrls.Training_GroupOpenLessons(
								':' + RouterVarNames.TrainingId,
								':' + RouterVarNames.GroupId
							).url
						}
						element={<OpenLessonsArticle />}
					/>
				</Route>
				<Route
					path={
						AppUrls.Lesson(
							':' + RouterVarNames.TrainingId,
							':' + RouterVarNames.LessonId
						).url
					}
					element={<LessonAdminPage />}
				/>
				<Route
					path={AppUrls.Students().url}
					element={<AdminStudentsPage />}
				/>
				<Route element={<StudentWrapArticle />}>
					<Route
						path={
							AppUrls.Student_Account(
								':' + RouterVarNames.StudentId
							).url
						}
						element={<StudentProfileArticle />}
					/>
					<Route
						path={
							AppUrls.Student_Orders(
								':' + RouterVarNames.StudentId
							).url
						}
						element={<StudentOrdersArticle />}
					/>
					<Route
						path={
							AppUrls.Student_Visits(
								':' + RouterVarNames.StudentId
							).url
						}
						element={<StudentVisitsArticle />}
					/>
					{/*<Route
						path={
							AppUrls.Student_Visit(
								':' + RouterVarNames.StudentId,
								':' + RouterVarNames.GroupId
							).url
						}
						element={<VisitArticle />}
					/>*/}
					<Route
						path={
							AppUrls.Student_History(
								':' + RouterVarNames.StudentId
							).url
						}
						element={<StudentHistoryArticle />}
					/>
				</Route>
				<Route path={AppUrls.Staff().url} element={<StaffArticle />} />
				<Route element={<EmployeePage />}>
					<Route
						path={
							AppUrls.Employee(':' + RouterVarNames.EmployeeId)
								.url
						}
						element={<EmployeeProfileArticle />}
					/>
					<Route
						path={
							AppUrls.Employee_Works(
								':' + RouterVarNames.EmployeeId
							).url
						}
						element={<EmployeeGroupsArticle />}
					/>
				</Route>
				<Route path={AppUrls.Leads().url} element={<LeadsPage />} />
				<Route
					path={AppUrls.AnswersFeed().url}
					element={<AnswersFeedPage />}
				/>
				<Route
					path={
						AppUrls.AnswersFeedGroup(':' + RouterVarNames.GroupId)
							.url
					}
					element={<AnswersFeedPage />}
				/>
				<Route
					path={AppUrls.Login().url}
					element={<Navigate to={AppUrls.Main().url} />}
				/>
				<Route path="*" element={<ErrorPage errorCode={404} />} />
			</Route>
		</Route>
	)
}
