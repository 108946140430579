import cn from 'classnames'
import { CardSticker } from 'ui/wrappers'
import { CardStickerColors } from 'ui/wrappers/CardSticker/CardSticker'
import { LeadsStatsStoreType } from '../../zustand/storeTypes'
import CardBottom from '../CardBottom/CardBottom'
import CardTop from '../CardTop/CardTop'
import s from './Card.module.scss'

type CardProps = {
	// Данные тарифа
	tariffPlan: LeadsStatsStoreType.TariffPlan
}

function Card(props: CardProps) {
	const { tariffPlan } = props

	if (tariffPlan.isMostExpensivePlan) {
		return (
			<CardSticker
				text="Лучший выбор"
				color={CardStickerColors.Green}
				extraClass={cn(
					s.wrapper,
					tariffPlan.isCurrentPlan && s['wrapper--current']
				)}
			>
				<CardContent tariffPlan={tariffPlan} />
			</CardSticker>
		)
	}

	return (
		<div
			className={cn(
				s.wrapper,
				tariffPlan.isCurrentPlan && s['wrapper--current']
			)}
		>
			<CardContent tariffPlan={tariffPlan} />
		</div>
	)
}

export default Card

type CardContentProps = {
	// Данные тарифа
	tariffPlan: LeadsStatsStoreType.TariffPlan
}

function CardContent(props: CardContentProps) {
	const { tariffPlan } = props

	return (
		<>
			<div className={s.topPartWrapper}>
				<CardTop tariffPlan={tariffPlan} />
				<div className={s.hr} />
			</div>
			<CardBottom tariffPlan={tariffPlan} />
		</>
	)
}
