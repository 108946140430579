import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import useSystemStore from 'parts/systemStore/systemStore'
import { dateStrToHumanDateStr } from 'parts/utils/time'
import useSubscriptionsStore from '../../../zustand/store'

export type TrialPlanData = {
	// Это пробный план оплаты?
	isTrialPlan: boolean
	// Если до окончания пробного периода больше 7 дней, то тут будет строка
	trialPlanEndDate: null | string
	// Если до окончания пробного периода меньше 7 дней, то тут будет объект
	trialPlanEndTime: null | {
		days: string
		hours: string
		minutes: string
		seconds: string
	}
}

export function useGetTrialPlanData() {
	const user = useSystemStore((store) => store.user)
	const tariffPlans = useSubscriptionsStore((store) => store.tariffPlans)

	const [trialPlanData, setTrialPlanData] = useState<null | TrialPlanData>(
		null
	)

	const [secondsTick, setSecondsTick] = useState(0)

	useEffect(
		function () {
			if (!tariffPlans) return
			const currentPlan = tariffPlans.find((plan) => plan.isCurrentPlan)
			if (!currentPlan) return

			if (!currentPlan.isTrialPlan) {
				setTrialPlanData({
					isTrialPlan: false,
					trialPlanEndDate: null,
					trialPlanEndTime: null,
				})

				return
			}

			const daysTrialPeriodLast = getDaysTrialPeriodLast(
				user.createdAt,
				user.tariffPlan.trialPeriod
			)

			// Если до конца пробного периода осталось более 7 дней
			if (daysTrialPeriodLast > 7) {
				setTrialPlanData({
					isTrialPlan: true,
					trialPlanEndDate: getTrialPeriodEndDateInHumanFormat(
						user.createdAt,
						user.tariffPlan.trialPeriod
					),
					trialPlanEndTime: null,
				})
			}
			// Если до конца пробного периода осталось менее 7 дней
			else {
				setTrialPlanData({
					isTrialPlan: true,
					trialPlanEndDate: null,
					trialPlanEndTime: getExactDateTrialPeriodLasts(
						user.createdAt,
						user.tariffPlan.trialPeriod
					),
				})
			}
		},
		[tariffPlans, secondsTick]
	)

	useEffect(function () {
		setInterval(() => {
			setSecondsTick(secondsTick + 1)
		}, 1000)
	}, [])

	return trialPlanData
}

function getDaysTrialPeriodLast(
	createdTime: string,
	trialPeriodInDays: number
): number {
	// Пробный период длится 14 дней.
	// Если отнять количество дней, прошедших после создания пользователя и автоматического применения пробного тарифного плана,
	// то получится количество дней оставшихся до конца действия пробного плана.
	const planWasCreatedDate = dayjs(createdTime)

	return (
		trialPeriodInDays - dayjs(new Date()).diff(planWasCreatedDate, 'days')
	)
}

function getTrialPeriodEndDateInHumanFormat(
	createdTime: string,
	trialPeriodInDays: number
) {
	const endDate = dayjs(createdTime).add(trialPeriodInDays, 'days')

	return dateStrToHumanDateStr(endDate)
}

function getExactDateTrialPeriodLasts(
	createdTime: string,
	trialPeriodInDays: number
) {
	const endDate = dayjs(createdTime).add(trialPeriodInDays, 'days')

	let days = dayjs(endDate).diff(new Date(), 'days')

	let hours = dayjs(endDate).diff(new Date(), 'hours') - days * 24

	let minutes =
		dayjs(endDate).diff(new Date(), 'minutes') - days * 24 * 60 - hours * 60

	let seconds =
		dayjs(endDate).diff(new Date(), 'seconds') -
		days * 24 * 60 * 60 -
		hours * 60 * 60 -
		minutes * 60

	let daysStr = days.toString()
	let hoursStr = hours < 10 ? '0' + hours : hours.toString()
	let minutesStr = minutes < 10 ? '0' + minutes : minutes.toString()
	let secondsStr = seconds < 10 ? '0' + seconds : seconds.toString()

	return {
		days: daysStr,
		hours: hoursStr,
		minutes: minutesStr,
		seconds: secondsStr,
	}
}
