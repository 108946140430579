import React, { useEffect } from 'react'
import { Form, FormInstance } from 'antd'
import { translite } from 'parts/utils/string'
import { setErrorsToFields } from 'parts/utils/form'
import useMakeSchoolStore from '../zustand/store'

export enum FieldNames {
	SchoolName = 'schoolName',
	Domain = 'domain',
}

export type FormValuesType = {
	[FieldNames.SchoolName]: string
	[FieldNames.Domain]: string
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useMakeSchoolStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}

/** Следит за изменением значения поля имени школы и ставит в поле домена школе транслитерированное значение домена. */
export function useManageSchoolDomainInput() {
	const form = Form.useFormInstance()
	const schoolNameValue = Form.useWatch(FieldNames.SchoolName, form)

	useEffect(
		function () {
			const transliteratedSchoolName = translite(schoolNameValue)
				.toLowerCase()
				.replaceAll(' ', '')

			form.setFields([
				{
					name: FieldNames.Domain,
					value: transliteratedSchoolName,
					errors: [],
				},
			])
		},
		[schoolNameValue]
	)
}

/**
 * Обработчик изменения поля домена школы.
 * Убирает ошибку при изменении.
 * @param {Object} e — объект события.
 * @param {Object} form — объект формы
 */
export function onSchoolDomainInputChange(
	e: React.ChangeEvent<HTMLInputElement>,
	form: FormInstance
) {
	form.setFields([
		{
			name: 'domain',
			value: e.target.value,
			errors: [],
		},
	])
}
