// НАЗВАНИЯ СТРАНИЦ. Используется в навигации и заголовках страниц.

const Auth = {
	Login: 'Вход',
	Register: 'Регистрация',
	Register_Owner_Start: 'Регистрация администратора',
	Register_Owner_School: 'Создание школы',
	Register_Methodist: 'Регистрация методиста',
	Register_ConfirmEmail: 'Ввод проверочного кода',
	RecoveryStart: 'Восстановление пароля',
	RecoveryCheck: 'Ввод кода подтверждения',
	RecoverySet: 'Установка нового пароля',
	RecoveryFinish: 'Пароль установлен',
	MainLanding: 'Лендинг',
	DeleteAccount: 'Удаление аккаунта',
	DeleteAccountConfirm: 'Подтверждение удаления аккаунта',
}

export const MainUrls = {
	Main: 'Главная',
	SchoolMain: 'Школа',
	Success: 'Успешна покупка',
	Fail: 'Покупка не удалась',
}

export const StudentUrls = {
	Students: 'Ученики',
	Student_Account: 'Профиль',
	Student_Orders: 'Заказы',
	Student_History: 'Журнал действий',
	Student_Visits: 'Посещения',
	Student_Visit: 'Курс',
}

export const StaffUrls = {
	Staff: 'Сотрудники',
	Employee: 'Сотрудники',
	Employee_Works: 'Группы сотрудника',
	Employee_Rights: 'Права пользователя',
}

const Training = {
	Trainings: 'Мои курсы',
	Training: 'Курс',
	Training_Groups: 'Группы',
	Training_LandingConstructor: 'Конструктор сайта',
	Training_LandingConstructorPreview: 'Предпросмотр одностраничника',
	Training_GroupConditions: 'Условия прохождения',
	Training_GroupPayments: 'Конструктор тарифов',
	Training_GroupCurators: 'Кураторы и менеджеры',
	Training_GroupOpenLessons: 'Открытые уроки',
	Lesson: 'Конструктор упражнения',
	Exercise: 'Прохождение упражнения',
	LandingConstructor: 'Конструктор сайта',
}

const My = {
	MySchools: 'Мои школы',
	MySchool: 'Моя школа',
	MyPayments: 'Мои системы оплат',
	MyPayment: 'Мои системы оплат',
	MySubscription: 'Моя подписка',
	MySubscriptionSuccess: 'Моя успешная подписка',
	MySubscriptionFail: 'Моя неудачная подписка',
	MyPaymentsHistory: 'История оплат',
}

const LeadsUrls = {
	Leads: 'Лиды/покупки',
}

const Product = {
	Products: 'Лента ответов',
}

const Message = {
	Messages: 'Сообщения',
}

const Answer = {
	AnswersFeed: 'Лента ответов',
	AnswersFeedGroup: 'Лента ответов',
}

const Profile = {
	Profile: 'Профиль',
}

const AudioCourse = {
	AudioCourse: 'Заговори на английском за 30 часов',
}

const urlRu = {
	auth: Auth,
	main: MainUrls,
	student: StudentUrls,
	staff: StaffUrls,
	training: Training,
	my: My,
	leads: LeadsUrls,
	product: Product,
	message: Message,
	answer: Answer,
	profile: Profile,
	audioCourse: AudioCourse,
}

export default urlRu
