import { ReactNode } from 'react'
import s from './ContentWrapper.module.scss'

type ContentWrapperProps = {
	icon?: ReactNode
	topText: string
	mainText: string
}

function ContentWrapper(props: ContentWrapperProps) {
	const { icon, topText, mainText } = props

	return (
		<div className={s.contentBlockWrapper}>
			{icon && icon}
			<div className={!icon && s.contentBlockTextsCenter}>
				<p className={s.contentBlockTopText}>{topText}</p>
				<p className={s.contentBlockMainText}>{mainText}</p>
			</div>
		</div>
	)
}

export default ContentWrapper
