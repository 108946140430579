import dayjs from 'dayjs'
import useSystemStore from 'parts/systemStore/systemStore'
import { dateStrToHumanDateStr } from 'parts/utils/time'

export function useGetNextPaymentDate() {
	const user = useSystemStore((store) => store.user)

	if (
		user.tariffPlan.isTrialPlan ||
		!user.tariffPlan.isPaid ||
		!user.tariffPlan.startDate
	) {
		return ''
	}

	const planStartDate = dayjs(user.tariffPlan.startDate)
	const planEndDate = planStartDate.add(1, user.tariffPlan.interval)

	return dateStrToHumanDateStr(planEndDate)
}
