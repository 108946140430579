import React from 'react'
import ContentWrapper from '../ContentWrapper/ContentWrapper'
import { useGetNextPaymentDate } from './fn/getNextPaymentDate'
import { TrialPlanData, useGetTrialPlanData } from './fn/getTrialPlanData'
import s from './MiddlePart.module.scss'

function MiddlePart() {
	const trialPlanData = useGetTrialPlanData()
	const nextPaymentDate = useGetNextPaymentDate()

	if (!trialPlanData) return null

	if (trialPlanData.isTrialPlan) {
		if (trialPlanData.trialPlanEndDate) {
			return (
				<ContentWrapper
					topText="Пробный период активен до"
					mainText={trialPlanData.trialPlanEndDate}
				/>
			)
		} else {
			return <CountDownCounter trialPlanData={trialPlanData} />
		}
	}

	return (
		<ContentWrapper
			topText="Дата следующей оплаты"
			mainText={nextPaymentDate}
		/>
	)
}

export default MiddlePart

type CountDownCounterProps = {
	trialPlanData: TrialPlanData
}

function CountDownCounter(props: CountDownCounterProps) {
	const time = props.trialPlanData.trialPlanEndTime

	if (!time) return null

	return (
		<div className={s.counter}>
			<p className={s.counterText}>Пробный период завершится через</p>
			<div className={s.counterDigits}>
				<div className={s.counterDigitSquare}>
					<span className={s.counterDigitNumber}>{time.days}</span>
					<span className={s.counterDigitText}>д.</span>
				</div>
				<div className={s.counterColon}>:</div>
				<div className={s.counterDigitSquare}>
					<span className={s.counterDigitNumber}>{time.hours}</span>
					<span className={s.counterDigitText}>ч.</span>
				</div>
				<div className={s.counterColon}>:</div>
				<div className={s.counterDigitSquare}>
					<span className={s.counterDigitNumber}>{time.minutes}</span>
					<span className={s.counterDigitText}>м.</span>
				</div>
				<div className={s.counterColon}>:</div>
				<div className={s.counterDigitSquare}>
					<span className={s.counterDigitNumber}>{time.seconds}</span>
					<span className={s.counterDigitText}>с.</span>
				</div>
			</div>
		</div>
	)
}
