import React from 'react'
import cn from 'classnames'
import LeftPart from '../LeftPart/LeftPart'
import MiddlePart from '../MiddlePart/MiddlePart'
import RightPart from '../RightPart/RightPart'
import s from './CurrentPlanPanel.module.scss'

function CurrentPlanPanel() {
	return (
		<div className={s.panelWrapper}>
			<div className={cn(s.panelSection, s['panelSection--hr'])}>
				<LeftPart />
			</div>

			<div className={cn(s.panelSection, s['panelSection--hr'])}>
				<MiddlePart />
			</div>

			<div className={s.panelSection}>
				<RightPart />
			</div>
		</div>
	)
}

export default CurrentPlanPanel
