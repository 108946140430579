import { Badge } from 'antd'
import {
	CalendarOutlined,
	FolderOutlined,
	IdcardOutlined,
	ReadOutlined,
	SolutionOutlined,
	TeamOutlined,
	UserOutlined,
	VideoCameraOutlined,
} from '@ant-design/icons'
import AppUrls from 'parts/constants/pageUrl'
import { ItemType } from 'antd/lib/menu/hooks/useItems'

export function adminMenuItems(
	canUseLiveLessons: boolean,
	canUseCRM: boolean,
	unansweredFeedCount: number
): ItemType[] {
	const items: ItemType[] = []

	if (canUseLiveLessons) {
		items.push({
			key: AppUrls.LiveRooms().url,
			icon: <VideoCameraOutlined />,
			label: AppUrls.LiveRooms().name,
		})
	}

	items.push({
		key: AppUrls.Trainings().url,
		icon: <ReadOutlined />,
		label: AppUrls.Trainings().name,
	})

	// if (canUseCRM) {
	// 	items.push({
	// 		key: AppUrls.Leads().url,
	// 		icon: <CalendarOutlined />,
	// 		label: AppUrls.Leads().name,
	// 	})
	// }

	items.push(
		{
			key: AppUrls.Students().url,
			icon: <IdcardOutlined />,
			label: AppUrls.Students().name,
		},
		{
			key: AppUrls.Staff().url,
			icon: <TeamOutlined />,
			label: AppUrls.Staff().name,
		},
		// {
		// 	key: AppUrls.AnswersFeed().url,
		// 	icon: getAnswersFeedSign(unansweredFeedCount),
		// 	label: AppUrls.AnswersFeed().name,
		// },
		{
			key: AppUrls.MyFiles().url,
			icon: <FolderOutlined />,
			label: AppUrls.MyFiles().name,
		}
	)

	return items
}

export function managerMenuItems(
	canUseLiveLessons: boolean,
	canUseCRM: boolean,
	unansweredFeedCount: number
) {
	const items: ItemType[] = []

	if (canUseLiveLessons) {
		items.push({
			key: AppUrls.LiveRooms().url,
			icon: <VideoCameraOutlined />,
			label: AppUrls.LiveRooms().name,
		})
	}

	items.push(
		{
			key: AppUrls.Trainings().url,
			icon: <ReadOutlined />,
			label: AppUrls.Trainings().name,
		},
		{
			key: AppUrls.Students().url,
			icon: <IdcardOutlined />,
			label: AppUrls.Students().name,
		},
		{
			key: AppUrls.Staff().url,
			icon: <TeamOutlined />,
			label: AppUrls.Staff().name,
		},
		// {
		// 	key: AppUrls.AnswersFeed().url,
		// 	icon: getAnswersFeedSign(unansweredFeedCount),
		// 	label: AppUrls.AnswersFeed().name,
		// },
		{
			key: AppUrls.Profile().url,
			icon: <UserOutlined />,
			label: AppUrls.Profile().name,
		},
		{
			key: AppUrls.MyFiles().url,
			icon: <FolderOutlined />,
			label: AppUrls.MyFiles().name,
		}
	)

	return items
}

export function curatorMenuItems(
	canUseLiveLessons: boolean,
	canUseCRM: boolean,
	unansweredFeedCount: number
) {
	const items: ItemType[] = []

	if (canUseLiveLessons) {
		items.push({
			key: AppUrls.LiveRooms().url,
			icon: <VideoCameraOutlined />,
			label: AppUrls.LiveRooms().name,
		})
	}

	items.push(
		{
			key: AppUrls.Trainings().url,
			icon: <ReadOutlined />,
			label: AppUrls.Trainings().name,
		},
		{
			key: AppUrls.Students().url,
			icon: <IdcardOutlined />,
			label: AppUrls.Students().name,
		},
		// {
		// 	key: AppUrls.AnswersFeed().url,
		// 	icon: getAnswersFeedSign(unansweredFeedCount),
		// 	label: AppUrls.AnswersFeed().name,
		// },
		{
			key: AppUrls.Profile().url,
			icon: <UserOutlined />,
			label: AppUrls.Profile().name,
		},
		{
			key: AppUrls.MyFiles().url,
			icon: <FolderOutlined />,
			label: AppUrls.MyFiles().name,
		}
	)

	return items
}

export function studentMenuItems(): ItemType[] {
	return [
		{
			key: AppUrls.LiveRooms().url,
			icon: <VideoCameraOutlined />,
			label: AppUrls.LiveRooms().name,
		},
		{
			key: AppUrls.Trainings().url,
			icon: <ReadOutlined />,
			label: AppUrls.Trainings().name,
		},
		// {
		// 	key: AppUrls.AnswersFeed().url,
		// 	icon: <SolutionOutlined />,
		// 	label: AppUrls.AnswersFeed().name,
		// },
		{
			key: AppUrls.Profile().url,
			icon: <UserOutlined />,
			label: AppUrls.Profile().name,
		},
	]
}

function getAnswersFeedSign(unansweredFeedCount: number) {
	if (!unansweredFeedCount) {
		return <SolutionOutlined />
	}

	return (
		<Badge
			count={unansweredFeedCount}
			className="main-menu-list__counter"
		/>
	)
}
