import cn from 'classnames'
import React from 'react'
import { Switch } from 'antd'
import useSubscriptionsStore from '../zustand/store'
import { useGetOnPaymentDurationChange } from './fn/onPaymentDurationChange'
import s from './TariffsHeader.module.scss'

function TariffsHeader() {
	return (
		<div className={s.generalWrapper}>
			<div>
				<h3 className={s.header}>Тарифные планы</h3>
				<DurationSwitcher />
			</div>
		</div>
	)
}

export default TariffsHeader

function DurationSwitcher() {
	const paymentDurationValue = useSubscriptionsStore(
		(store) => store.paymentDurationValue
	)

	const onChange = useGetOnPaymentDurationChange()

	return (
		<div className={s.switcherBlock}>
			<span
				className={cn(
					s.switcherText,
					paymentDurationValue === 'month' && s.switcherBoldText
				)}
			>
				Ежемесячно
			</span>
			<Switch
				onChange={onChange}
				checked={paymentDurationValue === 'year'}
			/>
			<span className={s.switcherRightPart}>
				<span
					className={cn(
						s.switcherText,
						paymentDurationValue === 'year' && s.switcherBoldText
					)}
				>
					За год
				</span>
				<span className={s.switcherDiscountText}>-20%</span>
			</span>
		</div>
	)
}
