import { Button, Form, Input, Checkbox } from 'antd'
import {
	MailOutlined,
	PhoneOutlined,
	ArrowRightOutlined,
} from '@ant-design/icons'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { log } from 'xstate'
import { useGetOnSubmit } from './fn/submit'
import {
	UFormNewBottomButtonsWithBorder,
	UFormNewGeneralError,
} from 'ui/UForm/UForm'
import { regExps } from 'parts/utils/regexp'
import Site from 'parts/constants/site'
import { UFormNewRow } from 'ui/UForm/UForm'
import useRegisterAdminStore from './zustand/store'
import { setErrorsToForm, useGetInitialValues } from './fn/form'
import './RegistrationForm.scss'

// Формы регистрации администратора
function RegistrationForm() {
	const [form] = Form.useForm()

	const initialValues = useGetInitialValues()
	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	const formErrors = useRegisterAdminStore((state) => state.formErrors)

	return (
		<Form
			form={form}
			name="register"
			initialValues={initialValues}
			onFinish={onSubmit}
			className="reg-owner-page-content"
			layout="vertical"
		>
			<EmailInput />
			<PhoneInput />
			<PasswordInput />
			<Legal />
			<UFormNewGeneralError message={formErrors.message} />
			<Bottom />
		</Form>
	)
}

export default RegistrationForm

// Обязательное поле почты
function EmailInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow>
			<Form.Item
				name="email"
				label={t('auth.startAdminRegFormFieldEmailLabel')}
				rules={[
					{
						required: true,
						message: t('auth.startAdminRegFormFieldEmailRequired'),
					},
					{
						type: 'email',
						message: t(
							'auth.startAdminRegFormFieldEmailWrongFormat'
						),
					},
				]}
			>
				<Input
					type="email"
					prefix={<MailOutlined />}
					placeholder="mail@mail.com"
					size="large"
				/>
			</Form.Item>
		</UFormNewRow>
	)
}

// Поле телефона
function PhoneInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name="phone"
				label={t('auth.startAdminRegFormFieldPhoneLabel')}
				rules={[
					{
						required: true,
						message: t('auth.startAdminRegFormFieldPhoneRequired'),
					},
					{
						pattern: regExps.phone,
						message:
							t('auth.startAdminRegFormFieldPhoneWrongFormat') +
							Site.examplePhone,
					},
				]}
			>
				<Input
					type="text"
					prefix={<PhoneOutlined />}
					placeholder="12345678901"
					size="large"
				/>
			</Form.Item>
		</UFormNewRow>
	)
}

function PasswordInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name="password"
				label={t('auth.startAdminRegFormFieldPasswordLabel')}
				rules={[
					{
						required: true,
						message: t(
							'auth.startAdminRegFormFieldPasswordRequired'
						),
					},
					{
						min: 6,
						message: t('auth.startAdminRegFormFieldPasswordMin'),
					},
					{
						max: 21,
						message: t('auth.startAdminRegFormFieldPasswordMax'),
					},
				]}
			>
				<Input.Password type="password" size="large" />
			</Form.Item>
		</UFormNewRow>
	)
}

// Флаг о принятии правил сервиса
function Legal() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name="legal"
				valuePropName="checked"
				rules={[
					{
						required: true,
						transform: (value) => value || undefined,
						type: 'boolean',
						message: t(
							'auth.startAdminRegFormFieldLegalCheckboxRequired'
						),
					},
				]}
			>
				<Checkbox>
					Регистрируясь, я принимаю{' '}
					<a href="/landings/pdf/User-agreement.pdf" target="_blank">
						«Пользовательское соглашение»
					</a>{' '}
					и{' '}
					<a href="/landings/pdf/Privacy-policy.pdf" target="_blank">
						«Политика конфиденциальности»
					</a>
				</Checkbox>
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const { t } = useTranslation()

	const form = Form.useFormInstance()
	const isSubmitting = useRegisterAdminStore((state) => state.isSubmitting)

	return (
		<UFormNewBottomButtonsWithBorder>
			<Form.Item
				shouldUpdate
				className="reg-owner-page-content__bottom-form-item"
			>
				{() => {
					const formErrors = form
						.getFieldsError()
						.filter(({ errors }) => errors.length)

					return (
						<Button
							size="large"
							block
							type="primary"
							htmlType="submit"
							disabled={isSubmitting || formErrors.length > 0}
							loading={isSubmitting}
						>
							{t('auth.startAdminRegFormSubmitButton')}{' '}
							<ArrowRightOutlined />
						</Button>
					)
				}}
			</Form.Item>
		</UFormNewBottomButtonsWithBorder>
	)
}
