import i18next from 'i18next'
import React from 'react'
import { useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { Dropdown } from 'antd'
import {
	BankOutlined,
	ContainerOutlined,
	DownOutlined,
	LogoutOutlined,
	UserOutlined,
} from '@ant-design/icons'
import { useGetUserRole, useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'
import Avatar from 'ui/Avatar/Avatar'
import UserService from 'parts/services/UserService'
import { getUserName } from 'parts/utils/string'
import useSystemStore from 'parts/systemStore/systemStore'
import './UserMenuTop.scss'
import './UserMenu.scss'
import TariffSign from 'pages/my/admin/mySubscription/common/TariffSign/TariffSign'
import { useGetTariffName } from './fn/getTariffName'
import './UserMenuPlan.scss'

// Меню сверху справа при наведении на портрет пользователя
function UserMenu() {
	const tariffName = useGetTariffName()

	if (tariffName) {
		return (
			<div className="user-menu-general">
				<div className="user-menu-plan">
					<span className="user-menu-plan__text user-menu-plan__text-left">
						Ваш тариф:
					</span>
					<TariffSign
						type="flag"
						extraClass="user-menu-plan__tariff-sign"
					/>
					<span className="user-menu-plan__text user-menu-plan__text-right">
						{tariffName}
					</span>
				</div>
				<UserMenuDropdown />
			</div>
		)
	}

	return <UserMenuDropdown />
}

export default UserMenu

function UserMenuDropdown() {
	const user = useSystemStore((store) => store.user)
	const tariffName = useGetTariffName()

	return (
		<Dropdown
			dropdownRender={() => (
				<div className="user-menu">
					<div className="user-menu__tip-w">
						<div className="user-menu__tip" />
					</div>
					<div className="user-menu__inner">
						<User />
						{tariffName && <Plan />}
						<My />
						<Bottom />
					</div>
				</div>
			)}
		>
			<div className="user-menu-top">
				<Avatar src={user.avatar} width={40} />
				<DownOutlined />
			</div>
		</Dropdown>
	)
}

function User() {
	const user = useSystemStore((store) => store.user)
	const userName = getUserName(user)

	return (
		<div className="user-menu__user">
			<Avatar src={user.avatar} width={40} />
			<div className="user-menu__user-texts">
				{userName && <p className="user-menu__user-name">{userName}</p>}
				<p className="user-menu__user-email">{user.email}</p>
			</div>
		</div>
	)
}

function Plan() {
	const tariffName = useSystemStore((store) => store.user.tariffPlan.name)

	const goToSubscriptionPage = useGoToPage(AppUrls.MySubscription().url)

	const userRoles = useGetUserRole()
	if (!userRoles.isAdmin) return null

	return (
		<div
			className="user-menu__block-wrapper-g"
			onClick={goToSubscriptionPage}
		>
			<div className="user-menu__block-wrapper">
				<div className="user-menu__block-left">
					<img
						src="/images/signs/CrownFilled.svg"
						alt="CrownFilled"
					/>
				</div>
				<div className="user-menu__block-right">
					<p className="user-menu__plan-name">{tariffName}</p>
					<p className="user-menu__plan-action">Улучшить план</p>
				</div>
			</div>
		</div>
	)
}

function My() {
	const userRoles = useGetUserRole()

	return (
		<div className="user-menu__block-wrapper-g">
			{!userRoles.isSuperAdmin && (
				<Link
					to={AppUrls.MySchools().url}
					className="user-menu__block-wrapper"
				>
					<div className="user-menu__block-left">
						<BankOutlined />
					</div>
					<div className="user-menu__block-right">Мои школы</div>
				</Link>
			)}
			{userRoles.isAdmin && (
				<Link
					to={AppUrls.MyPayments().url}
					className="user-menu__block-wrapper"
				>
					<div className="user-menu__block-left">
						<ContainerOutlined />
					</div>
					<div className="user-menu__block-right">
						Мои системы оплат
					</div>
				</Link>
			)}
			{userRoles.isAdmin && (
				<Link
					to={AppUrls.Profile().url}
					className="user-menu__block-wrapper"
				>
					<div className="user-menu__block-left">
						<UserOutlined />
					</div>
					<div className="user-menu__block-right">
						{AppUrls.Profile().name}
					</div>
				</Link>
			)}
		</div>
	)
}

function Bottom() {
	const queryClient = useQueryClient()

	return (
		<div className="user-menu__block-wrapper-g">
			{/*Потом расскомментировать как появится техподдержка*/}
			{/*<Link to={AppUrls.Main().url} className="user-menu__block-wrapper">
				<div className="user-menu__block-left">
					<QuestionOutlined />
				</div>
				<div className="user-menu__block-right">Тех. поддержка</div>
			</Link>*/}
			<div
				className="user-menu__block-wrapper"
				onClick={UserService.logout}
			>
				<div className="user-menu__block-left">
					<LogoutOutlined />
				</div>
				<div className="user-menu__block-right">Выход</div>
			</div>
		</div>
	)
}
