import React from 'react'
import ReactAudioPlayer from 'react-audio-player'
import './AudioFrame.scss'

type AudioFrameProps = {
	src: string
}

// Обёртка над проигрывателем голосовых файлов
function AudioFrame(props: AudioFrameProps) {
	const { src } = props

	return (
		<ReactAudioPlayer
			src={src}
			autoPlay={false}
			controls
			className="exercise-audio"
		/>
	)
}

export default AudioFrame
