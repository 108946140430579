import useSubscriptionsStore from '../../zustand/store'

export function useGetOnPaymentDurationChange() {
	const updateSubscriptionsStore = useSubscriptionsStore(
		(store) => store.updateStore
	)

	return function (checked: boolean) {
		updateSubscriptionsStore({
			paymentDurationValue: checked ? 'year' : 'month',
		})
	}
}
