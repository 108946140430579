import PaymentsApiTypes from 'parts/requests/payments/paymentsApiTypes'
import PaymentTypes from 'parts/types/PaymentTypes'
import { LeadsStatsStoreType } from '../storeTypes'
import { getIconType } from './getIconType'
import { getEurPricesObj } from './getPrices'
import { getRestrictions } from './getRestrictions'
import { isMostExpensivePlan } from './isWorstOrBetterPlan'

export function convertTariffPlansResToStoreTariffPlans(
	userTariffPlan: PaymentTypes.TariffPlan,
	tariffPlansRes: PaymentsApiTypes.GetTariffPlans
): LeadsStatsStoreType.TariffPlan[] {
	const availableTariffPlans = tariffPlansRes.filter(
		(tariff) => !tariff.isHidden
	)

	return availableTariffPlans.map((tariffRes) => {
		return {
			id: tariffRes.id,
			isCurrentPlan: userTariffPlan.id === tariffRes.id,
			isTrialPlan: tariffRes.isTrialPlan,
			trialPeriod: tariffRes.trialPeriod,
			name: tariffRes.name,
			description: tariffRes.description,
			iconType: getIconType(tariffRes, tariffPlansRes),
			restrictions: getRestrictions(tariffRes.restrictions),
			isFreePlan: !tariffRes.isPaid,
			prices: getEurPricesObj(tariffRes),
			isMostExpensivePlan: isMostExpensivePlan(tariffRes, tariffPlansRes),
		}
	})
}
