import { AxiosResponse } from 'axios'
import { paymentsQuery } from 'parts/requests/payments/paymentsQuery'
import PaymentTypes from 'parts/types/PaymentTypes'
import AppUrls from 'parts/constants/pageUrl'
import Site from 'parts/constants/site'
import PaymentsApiTypes from 'parts/requests/payments/paymentsApiTypes'
import { dateStrToHumanDateStr, dateStrToHumanTimeStr } from 'parts/utils/time'
import { UrlQueries } from '../../../../MySubscriptionNotification/fn/getTexts'
import useSubscriptionsStore from '../../../zustand/store'
import { LeadsStatsStoreType } from '../../../zustand/storeTypes'

/**
 * Возвращает обработчик оплаты тарифного плана.
 * @param paymentSystem — платёжная систем, с помощью которой будет проводиться оплата
 * @param tariffPlan — тарифный план, который будут оплачивать
 */
export function useGetPayTariffPlan(
	paymentSystem: PaymentTypes.System,
	tariffPlan: LeadsStatsStoreType.TariffPlan,
	interval: LeadsStatsStoreType.PaymentDurationValue
) {
	const { mutate } = paymentsQuery.payTariffPlan.useMutation({
		onSuccess: getOnSuccess(),
	})

	const successUrl = useGetSuccessUrl(tariffPlan)
	const cancelUrl = useGetFailUrl()

	return function () {
		mutate({
			paymentSystem: paymentSystem,
			// Адрес, куда нужно отправить пользователя при успешной оплате
			successUrl,
			// Адрес, куда нужно отправить пользователя при неудачной оплате
			cancelUrl,
			tariffPlanId: tariffPlan.id,
			interval: interval,
		})
	}
}

function getOnSuccess() {
	return function (res: AxiosResponse<PaymentsApiTypes.PayTariffPlan>) {
		window.location.href = res.data.url
	}
}

function useGetFailUrl() {
	const siteRootUrl = Site.protocol + Site.schoolDomain

	return siteRootUrl + AppUrls.MySubscriptionFail().url
}

function useGetSuccessUrl(tariffPlan: LeadsStatsStoreType.TariffPlan) {
	const paymentDurationValue = useSubscriptionsStore(
		(store) => store.paymentDurationValue
	)
	const siteRootUrl = Site.protocol + Site.schoolDomain

	const payDate =
		UrlQueries.paymentDate +
		'=' +
		dateStrToHumanDateStr(new Date()) +
		' ' +
		dateStrToHumanTimeStr(new Date())

	const planName = UrlQueries.planName + '=' + tariffPlan.name

	const price =
		paymentDurationValue === 'year'
			? tariffPlan.prices.annuallyPrice
			: tariffPlan.prices.monthlyPrice

	const sum = UrlQueries.sum + '=' + price + tariffPlan.prices.currency

	const url =
		siteRootUrl +
		AppUrls.MySubscriptionSuccess().url +
		'?' +
		payDate +
		'&' +
		planName +
		'&' +
		sum

	return encodeURI(url)
}
